import "./dialog.css";

function dialog1(closeDialog) {
  return (
    <div className="dialog-overlay">
      <div className="dialog-content">
        <div className="dialog-header">
          <h2>개인정보처리방침</h2>
          <button onClick={closeDialog} className="close-button">
            x
          </button>
        </div>
        <div className="dialog-body">
          <p>
            [도레미카](이하 "수집자")은(는) 대한민국 개인정보 보호법(PIPA)에
            따라 개인정보를 책임있게 처리하기 위해 최선을 다하고 있습니다. 이
            문서에는 개인 정보가 수집, 사용 및 제3자와 공유되는 방법이 간략하게
            설명되어 있습니다.
          </p>
          <h3>1. 개인정보 수집 목적</h3>
          <p>
            수집자는 원활한 고객님과 의사소통을 위해 지정된 담당자에게 SMS 혹은
            카카오 알림톡을 통해 전화번호를 전달하는 목적으로만 개인정보를
            수집하고 처리합니다.
          </p>
          <h3>2. 수집하는 개인정보 항목</h3>
          <p>
            수집자는 서비스 제공을 위하여 다음의 개인정보를 수집하고 있습니다.
          </p>
          <table>
            <thead>
              <tr>
                <th>수집목적</th>
                <th>수집항목</th>
                <th>보유기간</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>제출된 요청에 대한 후속 커뮤니케이션을 활성화합니다.</td>
                <td>전화번호</td>
                <td>sms 혹은 카카오 알림톡 전송 이후 즉시 폐기</td>
              </tr>
            </tbody>
          </table>
          <h3>3. 보유 및 이용기간</h3>
          <p>
            수집된 전화번호는 수집가에 의해 저장되거나 유지되지 않습니다. 해당
            목적으로만 사용되며, 지정된 담당자에게 전송된 후 즉시 폐기됩니다.
          </p>

          <h3>4. 개인정보의 제3자 제공</h3>
          <p>
            수집자는 수집된 전화번호를 지정된 담당자에게 다음과 같이 제공합니다.
          </p>
          <table>
            <thead>
              <tr>
                <th>항목</th>
                <th>내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>수혜자</td>
                <td>지정된 담당자</td>
              </tr>
              <tr>
                <td>목적</td>
                <td>제출된 요청에 대한 후속 커뮤니케이션을 활성화합니다.</td>
              </tr>
              <tr>
                <td>제공정보</td>
                <td>전화번호</td>
              </tr>
              <tr>
                <td>수신자별 보유기간</td>
                <td>x</td>
              </tr>
            </tbody>
          </table>

          <h3>5. 개인의 권리</h3>
          <p>
            개인은 다음과 같은 권리를 갖습니다.
            <br />
            1. 개인정보 수집 또는 제공에 대한 동의를 거부할 수 있습니다. <br />
            2. 정보가 전송되기 전에 언제든지 동의를 철회할 수 있습니다.
            <br />
            <br />
            동의를 거부하면 징수자가 후속 조치를 위해 전화번호를 전달하지 못할
            수도 있습니다.
          </p>

          <h3>6. 개인정보 보호 조치</h3>
          <p>
            전화번호는 이용 후 어떠한 형태(로그, 파일 등)로도 보관되지 않습니다.
          </p>
        </div>
      </div>
    </div>
  );
}

export default dialog1;
