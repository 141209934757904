import React, { useState, useRef } from "react";
import "./CarInquiryForm.css";
import icon1 from "./free-icon-check-1828644.png";
import dialog1 from "./dialog1";
import dialog2 from "./dialog2";
const { SolapiMessageService } = require("solapi");

function CarInquiryForm() {
  const [vehicleType, setVehicleType] = useState("장기렌트");
  const [terms1Checked, setTerms1Checked] = useState(true);
  const [terms2Checked, setTerms2Checked] = useState(true);
  const [mobileNumber, setMobileNumber] = useState("");
  const [wishCar, setWishCar] = useState("");
  const [inquiries, setInquiries] = useState("");
  const [showTerms1Dialog, setShowTerms1Dialog] = useState(false);
  const [showTerms2Dialog, setShowTerms2Dialog] = useState(false);
  const [count, setCount] = useState(0);
  const mobileNumberRef = useRef(null);
  const wishCarRef = useRef(null);
  const inquiriesRef = useRef(null);

  const handleTermsClick = (num) => {
    if (num === 1) {
      setShowTerms1Dialog(true);
    } else {
      setShowTerms2Dialog(true);
    }
  };

  const closeDialog = () => {
    setShowTerms1Dialog(false);
    setShowTerms2Dialog(false);
  };

  const changeMobileNumber = (e) => {
    setMobileNumber(e.target.value);
  };
  const changeWishCar = (e) => {
    setWishCar(e.target.value);
  };

  const changeInquiries = (e) => {
    setInquiries(e.target.value);
  };

  const resetValue = () => {
    setMobileNumber("");
    setWishCar("");
    setInquiries("");
    setVehicleType("장기렌트");
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      if (count >= 5) {
        alert("과도한 문의 신청이 감지되었습니다. \n문의 신청을 종료합니다.");
        resetValue();
        return "";
      }

      if (mobileNumber.length > 13) {
        mobileNumberRef.current.focus();
        throw { customError: true, msg: "전화번호를 올바르게 입력해주세요." };
      }

      if (wishCar.trim().length > 20) {
        wishCarRef.current.focus();
        throw {
          customError: true,
          msg: "희망 차종은 20자를 넘을 수 없습니다.",
        };
      }

      if (inquiries.trim().length > 30) {
        inquiriesRef.current.focus();
        throw {
          customError: true,
          msg: "추가 문의 내용은 30자를 넘을 수 없습니다.",
        };
      }

      const messageService = new SolapiMessageService(
        process.env.REACT_APP_SOLAPI_API_KEY,
        process.env.REACT_APP_SOLAPI_API_SECRET
      );

      await messageService.send({
        to: "01022442797",
        from: "01022442797",
        kakaoOptions: {
          pfId: process.env.REACT_APP_PFID,
          templateId: process.env.REACT_APP_TEMPLATE_ID,
          variables: {
            "#{mobileNumber}": mobileNumber,
            "#{wishCar}": wishCar,
            "#{inquiries}": inquiries,
            "#{vehicleType}": vehicleType,
          },

          disableSms: true,
        },
      });

      alert("문의가 정상적으로 접수되었습니다. \n감사합니다.");
      setCount(count + 1);
      resetValue();
    } catch (e) {
      console.log(e);
      if (e.customError) {
        alert(e.msg);
      } else {
        alert("알 수 없는 문제가 발생했습니다.");
      }
    }
  };

  const call = () => {
    window.location.href = `tel:01022442797`;
  };

  const openUrl = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const mobileDevices =
      /android|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile/i;
    const isMobile = mobileDevices.test(userAgent);

    if (isMobile) {
      window.location.href =
        "kakaotalk://inappbrowser/open?url=http://pf.kakao.com/_xkxoUxdn";
    } else {
      // 페이지 이동
      window.location.href = "http://pf.kakao.com/_xkxoUxdn";
      // 새로운 페이지
      // window.open("http://pf.kakao.com/_xkxoUxdn", "_blank");
    }
  };

  return (
    <div className="car-inquiry-wrapper">
      <div className="car-inquiry-form">
        <header className="header">
          <h3>JOB CAR</h3>
        </header>

        <div className="content-container">
          <div className="title-section">
            <div className="main-title">
              <img src={icon1} className="icon1"></img>
              <h2> 기다리지 마세요!,</h2>
            </div>
            <div className="sub-title">
              <span className="accent5">"신속" </span>
              <span className="accent5">"정확" </span>
              <span className="accent5">"실시간" </span>
              <br />
              상담 안내드립니다.
            </div>

            <p className="description">
              <span className="underline">
                친절한 상담사와 견적을 확인하세요!
              </span>
            </p>
          </div>

          <div className="form-section">
            <div className="info-box">
              <h3>
                <span className="accent fontup">
                  📌&nbsp;<span className="rotated">매</span> 달 !&nbsp;
                </span>
                할인정보 및 견적서 안내
              </h3>
              <p>
                구구절절 상담사는 이제 그만
                <br />
                모든 차량정보와 프로모션을 찾아드립니다.
              </p>
            </div>

            <form className="inquiry-form" onSubmit={handleSubmit}>
              <h2 className="inquiry-form-title">각종차종 문의하기 🚙</h2>
              <p className="inquiry-form-sub-title">
                <span className="font-gray">
                  👉 실시간 전화는 즉시매칭이 가능합니다.
                </span>
              </p>

              <div className="form-group-flex">
                <label className="form-label" htmlFor="contact">
                  연락처 *
                </label>
                <input
                  type="tel"
                  className="form-input"
                  id="contact"
                  placeholder="'-' 없이 숫자만 입력해주세요"
                  onChange={changeMobileNumber}
                  value={mobileNumber}
                  ref={mobileNumberRef}
                  required
                />
              </div>

              <div className="form-group-flex">
                <label className="form-label" htmlFor="carType">
                  희망차종
                </label>
                <input
                  type="text"
                  id="carType"
                  className="form-input"
                  onChange={changeWishCar}
                  value={wishCar}
                  ref={wishCarRef}
                  placeholder="차량명을 입력해주세요"
                />
              </div>

              <div className="form-group-flex">
                <label className="form-label">기타사항</label>
                <input
                  type="text"
                  className="form-input"
                  onChange={changeInquiries}
                  value={inquiries}
                  placeholder="추가 문의사항을 입력해주세요. (최대 30자)"
                  ref={inquiriesRef}
                />
              </div>

              <div className="form-group">
                <label>구매방법 *</label>
                <div className="button-group">
                  {["장기렌트", "리스", "기타"].map((type) => (
                    <button
                      key={type}
                      type="button"
                      className={vehicleType === type ? "active" : ""}
                      onClick={() => setVehicleType(type)}
                    >
                      {type}
                    </button>
                  ))}
                </div>
              </div>

              <div className="terms-section">
                <div className="term-item">
                  <div className="custom-checkbox-container">
                    <input
                      type="checkbox"
                      id="terms1"
                      required
                      checked={terms1Checked}
                      onChange={() => setTerms1Checked(!terms1Checked)}
                      className="custom-checkbox-input"
                    />
                    <label htmlFor="terms1" className="custom-checkbox-label">
                      <span className="custom-checkbox-box"></span>
                      <span className="font-gray">
                        [필수] 개인정보 수집 이용 제공 동의
                      </span>
                    </label>
                  </div>
                  &nbsp;&nbsp;
                  <span className="arrow" onClick={() => handleTermsClick(1)}>
                    <span className="font-gray">[보기]</span>
                  </span>
                </div>

                <div className="term-item">
                  <div className="custom-checkbox-container">
                    <input
                      type="checkbox"
                      id="terms2"
                      required
                      checked={terms2Checked}
                      onChange={() => setTerms2Checked(!terms2Checked)}
                      className="custom-checkbox-input"
                    />
                    <label htmlFor="terms2" className="custom-checkbox-label">
                      <span className="custom-checkbox-box"></span>
                      <span className="font-gray">
                        [필수] 개인정보 제3자 제공 동의
                      </span>
                    </label>
                  </div>
                  &nbsp;&nbsp;
                  <span className="arrow" onClick={() => handleTermsClick(2)}>
                    <span className="font-gray">[보기]</span>
                  </span>
                </div>
              </div>

              <button type="submit" className="submit-button">
                <span className="font-bold fontup">상담 문의하기</span>
              </button>

              <div className="experience-badge">
                <span className="badge-text">
                  상담도 <span className="badge-text-accent">"즐겁게"</span>{" "}
                  할수 있는 플래너
                </span>
                <span className="check-icon">✓</span>
                <p>
                  세상의 모든 차종 <span className="fontup">" 잡카 "</span>에서
                  찾으세요.
                  <br />
                  가장 다양한 방법으로 합리적인 금액을 안내합니다.
                  <br />
                  궁금한 모든 내용을 재치있고 센스있게 해결해드립니다.
                  <br />
                  상담도 즐겁다는 감정을 느끼도록 최선을 다하도록 하겠습니다.
                </p>
              </div>
            </form>
          </div>
        </div>

        <header className="footer">
          <br />
          금융회사의 위탁계약을 체결한 대출모집법인에 소속되어 있으며 협회에
          등록된 대출상담사
          <br />
          대출상담사 등록번호 10-00043844
        </header>
        {showTerms1Dialog && dialog1(closeDialog)}
        {showTerms2Dialog && dialog2(closeDialog)}

        {!showTerms1Dialog && !showTerms2Dialog && (
          <div className="fixed-bottom">
            <button className="consult-button yellow" onClick={() => openUrl()}>
              🗨 오픈채팅 문의
            </button>
            <button className="consult-button gray" onClick={() => call()}>
              📞 전화 문의
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default CarInquiryForm;
