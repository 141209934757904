import "./dialog.css";

function dialog2(closeDialog) {
  return (
    <div className="dialog-overlay">
      <div className="dialog-content">
        <div className="dialog-header">
          <h2>개인정보 제3자 제공에 대한 동의</h2>
          <button onClick={closeDialog} className="close-button">
            x
          </button>
        </div>
        <div className="dialog-body">
          <h3>본인은 다음과 같이 개인정보의 제3자 제공에 동의합니다.</h3>
          <table>
            <thead>
              <tr>
                <th>항목</th>
                <th>내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>수혜자</td>
                <td>지정된 담당자</td>
              </tr>
              <tr>
                <td>목적</td>
                <td>제출된 요청에 대한 후속 커뮤니케이션을 활성화합니다.</td>
              </tr>
              <tr>
                <td>제공정보</td>
                <td>전화번호</td>
              </tr>
              <tr>
                <td>보유 및 이용 기간</td>
                <td>
                  수집가는 전화번호를 보관하지 않습니다. 즉시 전달되어
                  폐기됩니다.
                </td>
              </tr>
            </tbody>
          </table>
          <h3>내 전화번호를 제공함으로써 다음 사항을 확인합니다.</h3>
          <p>
            - 수집 및 이용목적을 숙지합니다. <br />
            - 지정된 담당자에게 내 전화번호가 SMS 혹은 카카오 알림톡으로
            전달되는 것에 동의합니다.
            <br />
          </p>
        </div>
      </div>
    </div>
  );
}

export default dialog2;
